import { OverwritePageRequestWithSortT, TruckStatusEnum } from 'common/utils/api/models';
import BaseTranziitApi, { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';
import { authApi } from 'common/utils/api/auth/factory';
import { MS_IN_MIN } from 'common/utils/time';

class CarrierTranziitApi extends BaseTranziitApi {
    private static instance: CarrierTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '/api',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): CarrierTranziitApi {
        if (!CarrierTranziitApi.instance) {
            CarrierTranziitApi.instance = new CarrierTranziitApi();
        }

        return CarrierTranziitApi.instance;
    }

    fetchAssetTracksByTransportOrder = async (
        transportationOrderId: Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderIdAssetsCoordinates.Get.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderIdAssetsCoordinates.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderIdAssetsCoordinates.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v2/carrier/transportation-orders/${transportationOrderId}/assets-coordinates`,
                expectedResponseContentType: 'json',
            },
        );
    };

    fetchTransportOrdersPage = async (
        apiFetchOrdersRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2CarrierTransportationOrders.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTransportationOrders.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTransportationOrders.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/transportation-orders`,
            query: apiFetchOrdersRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrailersPage = async (
        apiFetchTrailersRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2CarrierTrailers.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailers.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailers.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trailers/`,
            query: apiFetchTrailersRequest,
            expectedResponseContentType: 'json',
        });
    };

    addTrailer = async (
        data: Api2Paths.ApiV2CarrierTrailers.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailers.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailers.Post.Responses.$201>({
            method: 'POST',
            path: '/v2/carrier/trailers/',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteTrailer = async (
        trailerId: Api2Paths.ApiV2CarrierTrailers$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailers$Id.Delete.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailers$Id.Delete.Responses.$204>({
            method: 'DELETE',
            path: `/v2/carrier/trailers/${trailerId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    assignTruckToContract = async (
        data: Partial<Api2Paths.ApiV2CarrierTrucksChangeContract.Post.RequestBody>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksChangeContract.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucksChangeContract.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/carrier/trucks/change-contract`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    assignTrailerToContract = async (
        data: Api2Paths.ApiV2CarrierTrailersChangeContract.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersChangeContract.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailersChangeContract.Post.Responses.$200>({
            method: 'POST',
            path: '/v2/carrier/trailers/change-contract',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    activateTrailer = async (
        data: Api2Paths.ApiV2CarrierTrailersActivate.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersActivate.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailersActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trailers/activate`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    archiveTrailer = async (
        data: Api2Paths.ApiV2CarrierTrailersArchive.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersArchive.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailersArchive.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trailers/archive`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchTrailerDetails = async (
        trailerId: Api2Paths.ApiV2CarrierTrailers$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailers$Id.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailers$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trailers/${trailerId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrailersActiveFleetCount = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersActiveFleetCount.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailersActiveFleetCount.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trailers/active-fleet-count`,
            expectedResponseContentType: 'json',
        });
    };

    updateTrailer = async (
        trailerId: Api2Paths.ApiV2CarrierTrailers$Id.Put.Parameters.Id,
        data: Api2Paths.ApiV2CarrierTrailers$Id.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailers$Id.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrailers$Id.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trailers/${trailerId}`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchActiveTrucksByIdPlateNumber = async (
        plateNumber: Api2Paths.ApiV2CarrierTrucks.Get.Parameters.PlateNumber,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks.Get.Responses.$200>> => {
        const apiFetchTrucksRequest: Api2Paths.ApiV2CarrierTrucks.Get.QueryParameters = {
            plateNumber,
            excludeStatuses: [TruckStatusEnum.archived],
        };

        return this.doFetch<Api2Paths.ApiV2CarrierTrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trucks`,
            query: apiFetchTrucksRequest,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrucksPage = async (
        apiFetchTrucksRequest: OverwritePageRequestWithSortT<Api2Paths.ApiV2CarrierTrucks.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trucks`,
            query: apiFetchTrucksRequest,
            expectedResponseContentType: 'json',
        });
    };

    addTruck = async (
        data: Api2Paths.ApiV2CarrierTrucks.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks.Post.Responses.$201>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucks.Post.Responses.$201>({
            method: 'POST',
            path: '/v2/carrier/trucks',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    activateTruck = async (
        data: Api2Paths.ApiV2CarrierTrucksActivate.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksActivate.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucksActivate.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trucks/activate`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    updateTruck = async (
        truckId: Api2Paths.ApiV2CarrierTrucks$Id.Put.Parameters.Id,
        data: Api2Paths.ApiV2CarrierTrucks$Id.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks$Id.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucks$Id.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trucks/${truckId}`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    archiveTruck = async (
        data: Api2Paths.ApiV2CarrierTrucksArchive.Put.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksArchive.Put.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucksArchive.Put.Responses.$200>({
            method: 'PUT',
            path: `/v2/carrier/trucks/archive`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchTruckDetails = async (
        truckId: Api2Paths.ApiV2CarrierTrucks$Id.Get.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks$Id.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucks$Id.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trucks/${truckId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchTrucksActiveFleetCount = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksActiveFleetCount.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucksActiveFleetCount.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/trucks/active-fleet-count`,
            expectedResponseContentType: 'json',
        });
    };

    deleteTruck = async (
        truckId: Api2Paths.ApiV2CarrierTrucks$Id.Delete.Parameters.Id,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucks$Id.Delete.Responses.$204>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierTrucks$Id.Delete.Responses.$204>({
            method: 'DELETE',
            path: `/v2/carrier/trucks/${truckId}`,
            expectedResponseContentType: 'unknown',
        });
    };

    getAvailableDrivers = async (
        transportationOrderId: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdDrivers.Get.Parameters.TransportationOrderId,
        driverName: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdDrivers.Get.Parameters.DriverName,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdDrivers.Get.Responses.$200>
    > => {
        const query: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdDrivers.Get.QueryParameters = {
            driverName,
        };

        return this.doFetch<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdDrivers.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/transportationOrder/${transportationOrderId}/drivers`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    assignDrivers = async (
        transportationOrderId: ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignDrivers.Post.Parameters.TransportationOrderId,
        query: ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignDrivers.Post.QueryParameters,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignDrivers.Post.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignDrivers.Post.Responses.$200>(
            {
                method: 'POST',
                path: `/v1/carrier/transportationOrder/${transportationOrderId}/assign-drivers`,
                query,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    reAssignDrivers = async (
        transportationOrderId: ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdRemoveDrivers.Post.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdRemoveDrivers.Post.Responses.$200>
    > => {
        return this.doFetch<ApiPaths.ApiV1CarrierTransportationOrder$TransportationOrderIdRemoveDrivers.Post.Responses.$200>(
            {
                method: 'POST',
                path: `/v1/carrier/transportationOrder/${transportationOrderId}/remove-drivers`,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    fetchCarrierContracts = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CompanyCarrierContracts.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CompanyCarrierContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/carrier-contracts`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierApprovedContracts = async (): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CompanyApprovedCarrierContracts.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CompanyApprovedCarrierContracts.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/approved-carrier-contracts`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierContractDetails = async (
        contractId: Api2Paths.ApiV1CompanyCarrierContracts$ContractId.Get.Parameters.ContractId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1CompanyCarrierContracts$ContractId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1CompanyCarrierContracts$ContractId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/carrier-contracts/${contractId}`,
            expectedResponseContentType: 'json',
        });
    };

    getTransportOrderDetails = async (
        transportationOrderId: Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderId.Get.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderId.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV2CarrierTransportationOrders$TransportationOrderId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/transportation-orders/${transportationOrderId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchDriverDetails = async (
        driverId: Api2Paths.ApiV2CarrierDrivers$DriverId.Get.Parameters.DriverId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers$DriverId.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers$DriverId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/drivers/${driverId}`,
            expectedResponseContentType: 'json',
        });
    };

    updateDriver = async (
        driverId: Api2Paths.ApiV2CarrierDrivers$DriverIdUpdate.Post.Parameters.DriverId,
        newDriverData: Api2Paths.ApiV2CarrierDrivers$DriverIdUpdate.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers$DriverIdUpdate.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers$DriverIdUpdate.Post.Responses.$200>({
            data: newDriverData,
            method: 'POST',
            path: `/v2/carrier/drivers/${driverId}/update`,
            expectedResponseContentType: 'json',
        });
    };

    activateDriver = async (
        driverId: Api2Paths.ApiV2CarrierDrivers$DriverIdActivate.Post.Parameters.DriverId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers$DriverIdActivate.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers$DriverIdActivate.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/carrier/drivers/${driverId}/activate`,
            expectedResponseContentType: 'unknown',
        });
    };

    archiveDriver = async (
        driverId: Api2Paths.ApiV2CarrierDrivers$DriverIdArchive.Post.Parameters.DriverId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers$DriverIdArchive.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers$DriverIdArchive.Post.Responses.$200>({
            method: 'POST',
            path: `/v2/carrier/drivers/${driverId}/archive`,
            expectedResponseContentType: 'unknown',
        });
    };

    deleteDriver = async (
        driverId: Api2Paths.ApiV2CarrierDrivers$DriverIdDelete.Delete.Parameters.DriverId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers$DriverIdDelete.Delete.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers$DriverIdDelete.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v2/carrier/drivers/${driverId}/delete`,
            expectedResponseContentType: 'unknown',
        });
    };

    inviteDriver = async (
        data: Api2Paths.ApiV2RegistrationInviteDriver.Post.RequestBody,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2RegistrationInviteDriver.Post.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2RegistrationInviteDriver.Post.Responses.$200>({
            method: 'POST',
            path: '/v2/registration/invite-driver',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchVehicleSchedule = async (
        vehicleId: ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Parameters.VehicleId,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV2CarrierVehiclescheduleVehicle$VehicleId.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/vehicleschedule/vehicle/${vehicleId}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchDriversPage = async (
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV2CarrierDrivers.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers.Get.Responses.$200>> => {
        // conflict statuses in filters
        if (query.statuses?.length === 0) {
            return [null, { content: [] }];
        }

        return this.doFetch<Api2Paths.ApiV2CarrierDrivers.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/drivers`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    searchDriver = async (
        query: OverwritePageRequestWithSortT<Api2Paths.ApiV2CarrierDrivers.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierDrivers.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV2CarrierDrivers.Get.Responses.$200>({
            method: 'GET',
            path: `/v2/carrier/drivers`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    checkTrailerGPSStatus = async (
        plateNumber: Api2Paths.ApiV2CarrierTrailersHasGps.Get.Parameters.PlateNumber,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrailersHasGps.Get.Responses.$200>> => {
        const query: Api2Paths.ApiV2CarrierTrailersHasGps.Get.QueryParameters = {
            plateNumber,
        };

        return this.doFetch<Api2Paths.ApiV2CarrierTrailersHasGps.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/carrier/trailers/has-gps',
            query,
            expectedResponseContentType: 'json',
        });
    };

    checkTruckGPSStatus = async (
        plateNumber: Api2Paths.ApiV2CarrierTrucksHasGps.Get.Parameters.PlateNumber,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV2CarrierTrucksHasGps.Get.Responses.$200>> => {
        const query: Api2Paths.ApiV2CarrierTrucksHasGps.Get.QueryParameters = {
            plateNumber,
        };

        return this.doFetch<Api2Paths.ApiV2CarrierTrucksHasGps.Get.Responses.$200>({
            method: 'GET',
            path: '/v2/carrier/trucks/has-gps',
            query,
            expectedResponseContentType: 'json',
        });
    };

    addVehicleScheduleEvent = async (
        data: ApiPaths.ApiV2CarrierVehiclescheduleVehicle.Post.RequestBody,
    ): Promise<TranziitApiResultT<ApiPaths.ApiV2CarrierVehiclescheduleVehicle.Post.Responses.$200>> => {
        return this.doFetch<ApiPaths.ApiV2CarrierVehiclescheduleVehicle.Post.Responses.$200>({
            method: 'POST',
            path: '/v2/carrier/vehicleschedule/vehicle',
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    findTrucksForTourByPlateNumber = async (
        transportationOrderId: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrucks.Get.Parameters.TransportationOrderId,
        query: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrucks.Get.QueryParameters,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrucks.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrucks.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/transportationOrder/${transportationOrderId}/trucks`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    findTrailersForTourByPlateNumber = async (
        transportationOrderId: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrailers.Get.Parameters.TransportationOrderId,
        query: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrailers.Get.QueryParameters,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrailers.Get.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdTrailers.Get.Responses.$200>(
            {
                method: 'GET',
                path: `/v1/carrier/transportationOrder/${transportationOrderId}/trailers`,
                query,
                expectedResponseContentType: 'json',
            },
        );
    };

    assignVehiclesToTransportOrder = async (
        transportationOrderId: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignAssets.Post.Parameters.TransportationOrderId,
        query: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignAssets.Post.QueryParameters,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignAssets.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdAssignAssets.Post.Responses.$200>(
            {
                method: 'POST',
                path: `/v1/carrier/transportationOrder/${transportationOrderId}/assign-assets`,
                query,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    unAssignVehicles = async (
        transportationOrderId: Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdUnassignAssets.Post.Parameters.TransportationOrderId,
    ): Promise<
        TranziitApiResultT<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdUnassignAssets.Post.Responses.$200>
    > => {
        return this.doFetch<Api2Paths.ApiV1CarrierTransportationOrder$TransportationOrderIdUnassignAssets.Post.Responses.$200>(
            {
                method: 'POST',
                path: `/v1/carrier/transportationOrder/${transportationOrderId}/unassign-assets`,
                expectedResponseContentType: 'unknown',
            },
        );
    };

    fetchCarrierContract = async (
        contractId: Api2Paths.ApiV1CompanyCarrierContracts$ContractIdSourceFile.Get.Parameters.ContractId,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1CompanyCarrierContracts$ContractIdSourceFile.Get.Responses.$200>> => {
        return this.doFetch<Api2Paths.ApiV1CompanyCarrierContracts$ContractIdSourceFile.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/company/carrier-contracts/${contractId}/source-file`,
            expectedResponseContentType: 'blob',
        });
    };

    uploadCarrierContract = async (
        file: File,
    ): Promise<TranziitApiResultT<Api2Paths.ApiV1CompanyCarrierContracts.Post.Responses.$200>> => {
        const formData = new FormData();
        formData.append('file', file);

        return this.doFetch<Api2Paths.ApiV1CompanyCarrierContracts.Post.Responses.$200>({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: 'POST',
            path: `/v1/company/carrier-contracts`,
            expectedResponseContentType: 'unknown',
        });
    };
}

const carrierTranziitApi = CarrierTranziitApi.getInstance();

export default carrierTranziitApi;
