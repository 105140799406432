import { RequestStatusT } from 'common/utils/request-status';

import { CompanyTrailersStatsT, FetchTrailersPageQueryT, ApiTrailerDetailsT, ApiTrailerT } from './models';
import { ApiTrailerAddRequestT, ApiTrailerUpdateRequestT } from 'common/utils/api/models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_TRAILERS_PAGE_REQUEST = 'common/trailers/FETCH_TRAILERS_PAGE_REQUEST';
export const FETCH_TRAILERS_PAGE_REQUEST_BEGIN = 'common/trailers/FETCH_TRAILERS_PAGE_REQUEST_BEGIN';
export const FETCH_TRAILERS_PAGE_REQUEST_ERROR = 'common/trailers/FETCH_TRAILERS_PAGE_REQUEST_ERROR';
export const FETCH_TRAILERS_PAGE_REQUEST_SUCCESS = 'common/trailers/FETCH_TRAILERS_PAGE_REQUEST_SUCCESS';

export const RESET_TRAILERS = 'common/trailers/RESET_TRAILERS';

export const ADD_TRAILER_REQUEST = 'common/trailers/ADD_TRAILER_REQUEST';
export const ADD_TRAILER_REQUEST_BEGIN = 'common/trailers/ADD_TRAILER_REQUEST_BEGIN';
export const ADD_TRAILER_REQUEST_ERROR = 'common/trailers/ADD_TRAILER_REQUEST_ERROR';
export const ADD_TRAILER_REQUEST_SUCCESS = 'common/trailers/ADD_TRAILER_REQUEST_SUCCESS';

export const DELETE_TRAILERS_REQUEST = 'common/trailers/DELETE_TRAILERS_REQUEST';
export const DELETE_TRAILERS_REQUEST_BEGIN = 'common/trailers/DELETE_TRAILERS_REQUEST_BEGIN';
export const DELETE_TRAILERS_REQUEST_ERROR = 'common/trailers/DELETE_TRAILERS_REQUEST_ERROR';
export const DELETE_TRAILERS_REQUEST_SUCCESS = 'common/trailers/DELETE_TRAILERS_REQUEST_SUCCESS';

export const ACTIVATION_TRAILERS_REQUEST = 'common/trailers/ACTIVATION_TRAILERS_REQUEST';
export const ARCHIVE_TRAILERS_REQUEST = 'common/trailers/ARCHIVE_TRAILERS_REQUEST';
export const ASSIGN_TO_CONTRACT_TRAILERS_REQUEST = 'common/trailers/ASSIGN_TO_CONTRACT_TRAILERS_REQUEST';
export const UPDATE_TRAILERS_REQUEST = 'common/trailers/UPDATE_TRAILERS_REQUEST';
export const UPDATE_TRAILERS_REQUEST_BEGIN = 'common/trailers/UPDATE_TRAILERS_REQUEST_BEGIN';
export const UPDATE_TRAILERS_REQUEST_ERROR = 'common/trailers/UPDATE_TRAILERS_REQUEST_ERROR';
export const UPDATE_TRAILERS_REQUEST_SUCCESS = 'common/trailers/UPDATE_TRAILERS_REQUEST_SUCCESS';

export const FETCH_TRAILER_DETAILS_REQUEST = 'common/trailers/FETCH_TRAILER_DETAILS_REQUEST';
export const FETCH_TRAILER_DETAILS_REQUEST_BEGIN = 'common/trailers/FETCH_TRAILER_DETAILS_REQUEST_BEGIN';
export const FETCH_TRAILER_DETAILS_REQUEST_ERROR = 'common/trailers/FETCH_TRAILER_DETAILS_REQUEST_ERROR';
export const FETCH_TRAILER_DETAILS_REQUEST_SUCCESS = 'common/trailers/FETCH_TRAILER_DETAILS_REQUEST_SUCCESS';

export const FETCH_TRAILERS_STATS_REQUEST = 'common/trailers/FETCH_TRAILERS_STATS_REQUEST';
export const FETCH_TRAILERS_STATS_REQUEST_BEGIN = 'common/trailers/FETCH_TRAILERS_STATS_REQUEST_BEGIN';
export const FETCH_TRAILERS_STATS_REQUEST_ERROR = 'common/trailers/FETCH_TRAILERS_STATS_REQUEST_ERROR';
export const FETCH_TRAILERS_STATS_REQUEST_SUCCESS = 'common/trailers/FETCH_TRAILERS_STATS_REQUEST_SUCCESS';

export type CompanyTrailersStateT = PaginationStateT<ApiTrailerT, FetchTrailersPageQueryT> & {
    addRequest: RequestStatusT;
    deleteRequest: RequestStatusT;
    updateRequest: RequestStatusT;
    detailsById: Record<TrailerIdT, ApiTrailerDetailsT | null>;
    fetchDetailsRequest: RequestStatusT;
    stats: CompanyTrailersStatsT | null;
    fetchStatsRequest: RequestStatusT;
};

export type TrailersStateT = Record<CompanyIdT, CompanyTrailersStateT | null>;

export type FetchTrailersPageActionT = {
    type: typeof FETCH_TRAILERS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchTrailersPageQueryT;
    options?: FetchOptionsT;
    companyId: CompanyIdT;
};

export type FetchTrailersPageBeginActionT = {
    type: typeof FETCH_TRAILERS_PAGE_REQUEST_BEGIN;
    query: FetchTrailersPageQueryT;
    pageNumber: PageNumberT;
    companyId: CompanyIdT;
};

export type FetchTrailersPageSuccessActionT = {
    type: typeof FETCH_TRAILERS_PAGE_REQUEST_SUCCESS;
    query: FetchTrailersPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<ApiTrailerT>;
    companyId: CompanyIdT;
};

export type FetchTrailersPageErrorActionT = {
    type: typeof FETCH_TRAILERS_PAGE_REQUEST_ERROR;
    query: FetchTrailersPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
    companyId: CompanyIdT;
};

export type ResetTrailersActionT = {
    type: typeof RESET_TRAILERS;
    companyId: CompanyIdT;
    mutationListOptions: MutationListOptionsT;
};

export type AddTrailerRequestActionT = {
    type: typeof ADD_TRAILER_REQUEST;
    data: ApiTrailerAddRequestT;
    companyId: CompanyIdT;
};

export type AddTrailerRequestBeginActionT = {
    type: typeof ADD_TRAILER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type AddTrailerRequestErrorActionT = {
    type: typeof ADD_TRAILER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type AddTrailerRequestSuccessActionT = {
    type: typeof ADD_TRAILER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type DeleteTrailersRequestActionT = {
    type: typeof DELETE_TRAILERS_REQUEST;
    trailerIds: TrailerIdT[];
    companyId: CompanyIdT;
};

export type DeleteTrailersRequestBeginActionT = {
    type: typeof DELETE_TRAILERS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type DeleteTrailersRequestErrorActionT = {
    type: typeof DELETE_TRAILERS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DeleteTrailersRequestSuccessActionT = {
    type: typeof DELETE_TRAILERS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type ActivationTrailersRequestActionT = {
    type: typeof ACTIVATION_TRAILERS_REQUEST;
    trailerIds: TrailerIdT[];
    ratesPerKm: Array<number | null>;
    companyId: CompanyIdT;
};

export type AssignToContractRequestActionT = {
    type: typeof ASSIGN_TO_CONTRACT_TRAILERS_REQUEST;
    trailerIds: TrailerIdT[];
    companyId: CompanyIdT;
    isContracted: boolean;
};

export type ArchiveTrailersRequestActionT = {
    type: typeof ARCHIVE_TRAILERS_REQUEST;
    trailerIds: TrailerIdT[];
    companyId: CompanyIdT;
    reason: string;
};

export type UpdateTrailerActionT = {
    type: typeof UPDATE_TRAILERS_REQUEST;
    companyId: CompanyIdT;
    trailerId: TrailerIdT;
    updateTrailer: ApiTrailerUpdateRequestT;
};

export type UpdateTrailersRequestBeginActionT = {
    type: typeof UPDATE_TRAILERS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type UpdateTrailersRequestErrorActionT = {
    type: typeof UPDATE_TRAILERS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type UpdateTrailersRequestSuccessActionT = {
    type: typeof UPDATE_TRAILERS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type FetchTrailerDetailsActionT = {
    type: typeof FETCH_TRAILER_DETAILS_REQUEST;
    companyId: CompanyIdT;
    trailerId: TrailerIdT;
    options?: FetchOptionsT;
};

export type FetchTrailerDetailsBeginActionT = {
    type: typeof FETCH_TRAILER_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchTrailerDetailsErrorActionT = {
    type: typeof FETCH_TRAILER_DETAILS_REQUEST_ERROR;
    companyId: CompanyIdT;
    error: Error;
};

export type FetchTrailerDetailsSuccessActionT = {
    type: typeof FETCH_TRAILER_DETAILS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
    trailerId: TrailerIdT;
    details: ApiTrailerDetailsT | null;
};

export type FetchTrailersStatsActionT = {
    type: typeof FETCH_TRAILERS_STATS_REQUEST;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchTrailersStatsBeginActionT = {
    type: typeof FETCH_TRAILERS_STATS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchTrailersStatsErrorActionT = {
    type: typeof FETCH_TRAILERS_STATS_REQUEST_ERROR;
    companyId: CompanyIdT;
    error: Error;
};

export type FetchTrailersStatsSuccessActionT = {
    type: typeof FETCH_TRAILERS_STATS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
    stats: CompanyTrailersStatsT | null;
};

export type TrailersActionT =
    | FetchTrailersPageActionT
    | FetchTrailersPageBeginActionT
    | FetchTrailersPageSuccessActionT
    | FetchTrailersPageErrorActionT
    | ResetTrailersActionT
    | AddTrailerRequestActionT
    | AddTrailerRequestBeginActionT
    | AddTrailerRequestErrorActionT
    | AddTrailerRequestSuccessActionT
    | DeleteTrailersRequestActionT
    | DeleteTrailersRequestBeginActionT
    | DeleteTrailersRequestErrorActionT
    | DeleteTrailersRequestSuccessActionT
    | ActivationTrailersRequestActionT
    | UpdateTrailerActionT
    | UpdateTrailersRequestBeginActionT
    | UpdateTrailersRequestErrorActionT
    | UpdateTrailersRequestSuccessActionT
    | FetchTrailersStatsActionT
    | FetchTrailersStatsBeginActionT
    | FetchTrailersStatsErrorActionT
    | FetchTrailersStatsSuccessActionT
    | ArchiveTrailersRequestActionT
    | FetchTrailerDetailsActionT
    | FetchTrailerDetailsBeginActionT
    | FetchTrailerDetailsErrorActionT
    | FetchTrailerDetailsSuccessActionT;
