import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TrucksTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { ApiTruckT } from 'common/store/trucks/models';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import ModelCell from './cell-renderers/ModelCell/ModelCell';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import PlateNumberCell from 'common/components/Table/cell-renderers/PlateNumberCell/PlateNumberCell';
import FlagCell from 'common/components/Table/cell-renderers/FlagCell/FlagCell';
import EmissionClassCell from 'common/layouts/TrucksPageLayout/TrucksTable/cell-renderers/EmissionClassCell/EmissionClassCell';
import { TabEnum } from 'common/layouts/TrucksPageLayout/constants';
import HumanReadableFullDateCell from 'common/components/Table/cell-renderers/HumanReadableFullDateCell/HumanReadableFullDateCell';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    trucks: Array<ApiTruckT>;
    className?: string;
    selectedTrucksSet: Set<ApiTruckT>;
    onSelectTrucks: (selectedTrucksSet: Set<ApiTruckT>) => void;
    onSelectRow: (event: React.MouseEvent, truck: ApiTruckT) => void;
    onOpenUserDetails: (userId: UserIdT | null, isBrokerUser?: boolean) => void;
    isLoading: boolean;
    canEditTrucks: boolean;
    activeTab: TabEnum;
};

const TrucksTable: React.FC<PropsT> = React.memo((props) => {
    const {
        trucks,
        canEditTrucks,
        className,
        selectedTrucksSet,
        onSelectRow,
        onSelectTrucks,
        isLoading,
        onOpenUserDetails,
        activeTab,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ApiTruckT, void> | null> = [
        canEditTrucks
            ? {
                  renderHeader: () => (
                      <CheckboxHeader<ApiTruckT>
                          selectedRowsSet={selectedTrucksSet}
                          rows={trucks}
                          onChange={onSelectTrucks}
                      />
                  ),
                  headerClassName: cx('table__header', 'table__header--checkbox'),
                  render: (truck: ApiTruckT) => (
                      <CheckboxCell<ApiTruckT>
                          row={truck}
                          selectedRowsSet={selectedTrucksSet}
                          onChange={onSelectTrucks}
                      />
                  ),
                  className: cx('table__column--checkbox'),
              }
            : null,
        {
            renderHeader: () => t('common:trucks-page.table.columns.plate-number'),
            headerClassName: cx('table__header'),
            render: (truck: ApiTruckT) => <PlateNumberCell plateNumber={truck?.plateNumber} />,
            className: cx('table__column--plate-number'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.model'),
            headerClassName: cx('table__header'),
            render: (truck: ApiTruckT) => <ModelCell truck={truck} />,
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.added-date'),
            headerClassName: cx('table__header'),
            render: (truck: ApiTruckT) => (
                <EventCell
                    userFullName={truck.createdBy?.fullName}
                    userId={truck.createdBy?.id}
                    date={truck.createdDate}
                    byBroker={truck.addedByBroker}
                    openUserDetails={onOpenUserDetails}
                />
            ),
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.emission-class'),
            headerClassName: cx('table__header', 'table__header--emission-class'),
            render: (truck: ApiTruckT) => <EmissionClassCell truck={truck} />,
            className: cx('table__column--model'),
        },
        // linked
    ];

    if (activeTab === TabEnum.active) {
        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.linked'),
            headerClassName: cx('table__header', 'table__header--linked'),
            render: (truck: ApiTruckT) => <FlagCell isShowFlag={!!truck.linked} />,
            className: cx('table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.dedicated'),
            headerClassName: cx('table__header', 'table__header--dedicated'),
            render: (truck: ApiTruckT) => <FlagCell isShowFlag={!!truck.dedicated} />,
            className: cx('table__column--any'),
        });

        columns.push({
            renderHeader: () => (
                <span>
                    {t('common:trucks-page.table.columns.GPS')}
                    <Tooltip
                        position={TooltipPositionEnum.topCenter}
                        theme={TooltipThemeEnum.black}
                        isInline
                        tooltipNode={
                            <TooltipContent isCenter theme={TooltipContentThemeEnum.black} isNoWrap>
                                {t('common:trucks-page.table.tooltips.GPS')}
                            </TooltipContent>
                        }
                    >
                        {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                    </Tooltip>
                </span>
            ),
            headerClassName: cx('table__header', 'table__header--gps'),
            render: (truck: ApiTruckT) => (
                <HumanReadableFullDateCell isBold isAllowNever date={truck.telematicUpdatedDate} />
            ),
            className: cx('table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.status'),
            headerClassName: cx('table__header'),
            render: (truck: ApiTruckT) => <StatusCell truck={truck} />,
            className: cx('table__column--status'),
        });
    }

    if (activeTab === TabEnum.archive) {
        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.archived'),
            headerClassName: cx('table__header'),
            render: (truck: ApiTruckT) => (
                <EventCell
                    userFullName={truck.archivedBy?.fullName}
                    userId={truck.archivedBy?.id}
                    byBroker={truck.archivedByBroker}
                    date={truck.archivedDate}
                    openUserDetails={onOpenUserDetails}
                />
            ),
            className: cx('table__column--any'),
        });
    }

    const getRowMods = (meta: TableRowMetaT) => {
        return {
            [TableRowModsEnum.isSelected]: meta.isSelected,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ApiTruckT> tableName="trucks" isLoading={isLoading} rows={trucks}>
                {(rows, isUsedPrevRows) => (
                    <Table<ApiTruckT, void>
                        className={className}
                        selectedRowsSet={selectedTrucksSet}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default TrucksTable;
