import React, { useCallback } from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import styles from './ActiveContractMultipleDropdown.scss';
import { useTranslation } from 'react-i18next';
import {
    AdditionalOptionFilterT,
    useAsyncFilterOptions,
} from 'design-system/components/dropdowns/DropdownSearchInput/hooks/use-async-filter-options';
import { AsyncRequestFactoryT } from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import { checkIsDefaultCompanyId } from 'common/utils';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { ApiPartnerContractT } from 'common/utils/api/models';
import ContractName from 'common/components/ContractName/ContractName';
import DropdownSearchMultipleInput from 'design-system/components/dropdowns/DropdownSearchMultipleInput/DropdownSearchMultipleInput';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import keyBy from 'lodash/keyBy';

const cx = classNames.bind(styles);

type ValueT = CarrierContractIdT | null;

type PropsT = {
    className?: string;
    placeholder?: string;
    inputPlaceholder?: string;
    values: Array<ValueT>;
    hasNoneOption?: boolean;
    isDisabled?: boolean;
    onChange: (values: Array<ValueT>, labels: Array<string>) => void;
    hasChanges?: boolean;
    companyId: CompanyIdT;
    onBlur: () => void;
    onFocus: () => void;
    hasError?: boolean;
    hasWarning?: boolean;
    excludedContractIds?: Array<CarrierContractIdT>;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasClearControl?: boolean;
};

type NoneOptionT = {
    id: null;
    name: string;
};

export type OptionT = ApiPartnerContractT | NoneOptionT;

const ActiveContractMultipleDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        values,
        placeholder,
        inputPlaceholder,
        onChange,
        hasNoneOption,
        isDisabled,
        hasChanges,
        onBlur,
        onFocus,
        hasError,
        hasWarning,
        companyId,
        excludedContractIds,
        overlayPosition,
        hasClearControl,
    } = props;

    const { t } = useTranslation();

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback(async () => {
        let options: Array<OptionT> = [];

        const isPartner = !checkIsDefaultCompanyId(companyId);
        if (isPartner) {
            const brokerResponse = await brokerTranziitApi.fetchCarrierApprovedContracts(companyId);
            const [brokerError, brokerResult] = brokerResponse;
            if (brokerError) {
                return [brokerError, null];
            }
            if (brokerResult) {
                options = brokerResult;
            }
        } else {
            const carrierResponse = await carrierTranziitApi.fetchCarrierApprovedContracts();
            const [carrierError, carrierResult] = carrierResponse;
            if (carrierError) {
                return [carrierError, null];
            }
            if (carrierResult) {
                options = carrierResult;
            }
        }

        if (hasNoneOption) {
            options.unshift({
                name: t('common:dedicated.none'),
                id: null,
            });
        }
        return [null, options];
    }, [companyId, excludedContractIds, hasNoneOption]);

    const additionalOptionFilter: AdditionalOptionFilterT<OptionT> = useCallback(
        (option) => {
            return !!option.id && !excludedContractIds?.includes(option.id);
        },
        [excludedContractIds],
    );

    const getOptionText = useCallback((option: OptionT) => option.name || '', []);

    const asyncFilterOptionsRequest = useAsyncFilterOptions<OptionT>({
        requestFactory,
        getOptionText,
        additionalOptionFilter,
    });

    const renderOption = (option: OptionT | null | undefined, placeholder?: string): React.ReactElement | null => {
        if (!option && hasNoneOption) {
            return t('common:dedicated.none');
        }

        if (!option) {
            return <div>{placeholder || null}</div>;
        }

        return (
            <div className={cx('option')} key={option.id}>
                <ContractName className={cx('contract-name')} name={option.name} />
            </div>
        );
    };

    const renderTrigger = (options: Array<OptionT>, placeholder: string | undefined) => {
        if (!options?.length) {
            return placeholder;
        }

        if (options.length === 1) {
            const firstOption = options?.[0];
            return <ContractName className={cx('contract-name')} name={firstOption?.name} />;
        }

        return t('common:dropdown-multiple-input.multiple-values-selected');
    };

    const renderRightIcon = () => {
        if (values?.length < 2) {
            return null;
        }

        return (
            <PillLabel isSymmetrical theme={PillLabelThemeEnum.charcoal}>
                {values.length}
            </PillLabel>
        );
    };

    const handleSelect = (values: Array<ValueT>): void => {
        const optionByValue = keyBy(asyncFilterOptionsRequest?.options, 'id');

        const labels = values.map((value) => {
            const option = optionByValue[value as string] || null;
            if (!option) {
                return '';
            }

            return option?.name || '';
        });

        onChange(values, labels);
    };

    const getOptionValue = (option: OptionT): ValueT => option.id || null;

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }

        asyncFilterOptionsRequest.onRefresh();
    }, [onFocus, asyncFilterOptionsRequest.onRefresh]);

    const handleReset = () => {
        handleSelect([]);
    };

    return (
        <DropdownSearchMultipleInput<OptionT, ValueT>
            className={className}
            selectedValues={values}
            placeholder={placeholder}
            inputPlaceholder={inputPlaceholder}
            options={asyncFilterOptionsRequest.options}
            isDisabled={isDisabled}
            onSelect={handleSelect}
            onReset={hasClearControl ? handleReset : undefined}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncFilterOptionsRequest.onChangeQuery}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            renderRightIcon={renderRightIcon}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            isLoading={asyncFilterOptionsRequest.requestStatus.loading}
            onFocus={handleFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default ActiveContractMultipleDropdown;
