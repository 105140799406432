import { RequestStatusT } from 'common/utils/request-status';

import { FetchTrucksPageQueryT, ApiTruckDetailsT, CompanyTrucksStatsT, ApiTruckT } from './models';
import { ApiTruckAddRequestT, ApiTruckUpdateRequestT } from 'common/utils/api/models';
import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_TRUCKS_PAGE_REQUEST = 'common/trucks/FETCH_TRUCKS_PAGE_REQUEST';
export const FETCH_TRUCKS_PAGE_REQUEST_BEGIN = 'common/trucks/FETCH_TRUCKS_PAGE_REQUEST_BEGIN';
export const FETCH_TRUCKS_PAGE_REQUEST_ERROR = 'common/trucks/FETCH_TRUCKS_PAGE_REQUEST_ERROR';
export const FETCH_TRUCKS_PAGE_REQUEST_SUCCESS = 'common/trucks/FETCH_TRUCKS_PAGE_REQUEST_SUCCESS';

export const RESET_TRUCKS = 'common/trucks/RESET_TRUCKS';

export const ADD_TRUCK_REQUEST = 'common/trucks/ADD_TRUCK_REQUEST';
export const ADD_TRUCK_REQUEST_BEGIN = 'common/trucks/ADD_TRUCK_REQUEST_BEGIN';
export const ADD_TRUCK_REQUEST_ERROR = 'common/trucks/ADD_TRUCK_REQUEST_ERROR';
export const ADD_TRUCK_REQUEST_SUCCESS = 'common/trucks/ADD_TRUCK_REQUEST_SUCCESS';

export const DELETE_TRUCKS_REQUEST = 'common/trucks/DELETE_TRUCKS_REQUEST';
export const DELETE_TRUCKS_REQUEST_BEGIN = 'common/trucks/DELETE_TRUCKS_REQUEST_BEGIN';
export const DELETE_TRUCKS_REQUEST_ERROR = 'common/trucks/DELETE_TRUCKS_REQUEST_ERROR';
export const DELETE_TRUCKS_REQUEST_SUCCESS = 'common/trucks/DELETE_TRUCKS_REQUEST_SUCCESS';

export const ACTIVATION_TRUCKS_REQUEST = 'common/trucks/ACTIVATION_TRUCKS_REQUEST';
export const ARCHIVE_TRUCKS_REQUEST = 'common/trucks/ARCHIVE_TRUCKS_REQUEST';
export const ASSIGN_TO_CONTRACT_TRUCKS_REQUEST = 'common/trucks/ASSIGN_TO_CONTRACT_TRUCKS_REQUEST';

export const UPDATE_TRUCK_REQUEST = 'common/trucks/UPDATE_TRUCK_REQUEST';
export const UPDATE_TRUCKS_REQUEST_BEGIN = 'common/trucks/UPDATE_TRUCKS_REQUEST_BEGIN';
export const UPDATE_TRUCKS_REQUEST_ERROR = 'common/trucks/UPDATE_TRUCKS_REQUEST_ERROR';
export const UPDATE_TRUCKS_REQUEST_SUCCESS = 'common/trucks/UPDATE_TRUCKS_REQUEST_SUCCESS';

export const FETCH_TRUCK_DETAILS_REQUEST = 'common/trucks/FETCH_TRUCK_DETAILS_REQUEST';
export const FETCH_TRUCK_DETAILS_REQUEST_BEGIN = 'common/trucks/FETCH_TRUCK_DETAILS_REQUEST_BEGIN';
export const FETCH_TRUCK_DETAILS_REQUEST_ERROR = 'common/trucks/FETCH_TRUCK_DETAILS_REQUEST_ERROR';
export const FETCH_TRUCK_DETAILS_REQUEST_SUCCESS = 'common/trucks/FETCH_TRUCK_DETAILS_REQUEST_SUCCESS';

export const FETCH_TRUCKS_STATS_REQUEST = 'common/trucks/FETCH_TRUCKS_STATS_REQUEST';
export const FETCH_TRUCKS_STATS_REQUEST_BEGIN = 'common/trucks/FETCH_TRUCKS_STATS_REQUEST_BEGIN';
export const FETCH_TRUCKS_STATS_REQUEST_ERROR = 'common/trucks/FETCH_TRUCKS_STATS_REQUEST_ERROR';
export const FETCH_TRUCKS_STATS_REQUEST_SUCCESS = 'common/trucks/FETCH_TRUCKS_STATS_REQUEST_SUCCESS';

export type CompanyTrucksStateT = PaginationStateT<ApiTruckT, FetchTrucksPageQueryT> & {
    addRequest: RequestStatusT;
    deleteRequest: RequestStatusT;
    updateRequest: RequestStatusT;
    detailsById: Record<TruckIdT, ApiTruckDetailsT | null>;
    fetchDetailsRequest: RequestStatusT;
    stats: CompanyTrucksStatsT | null;
    fetchStatsRequest: RequestStatusT;
};

export type TrucksStateT = Record<CompanyIdT, CompanyTrucksStateT | null>;

export type FetchTrucksPageActionT = {
    type: typeof FETCH_TRUCKS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchTrucksPageQueryT;
    options?: FetchOptionsT;
    companyId: CompanyIdT;
};

export type FetchTrucksPageBeginActionT = {
    type: typeof FETCH_TRUCKS_PAGE_REQUEST_BEGIN;
    query: FetchTrucksPageQueryT;
    pageNumber: PageNumberT;
    companyId: CompanyIdT;
};

export type FetchTrucksPageSuccessActionT = {
    type: typeof FETCH_TRUCKS_PAGE_REQUEST_SUCCESS;
    query: FetchTrucksPageQueryT;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<ApiTruckT> | null;
    companyId: CompanyIdT;
};

export type FetchTrucksPageErrorActionT = {
    type: typeof FETCH_TRUCKS_PAGE_REQUEST_ERROR;
    query: FetchTrucksPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
    companyId: CompanyIdT;
};

export type ResetTrucksActionT = {
    type: typeof RESET_TRUCKS;
    companyId: CompanyIdT;
    mutationListOptions: MutationListOptionsT;
};

export type AddTruckRequestActionT = {
    type: typeof ADD_TRUCK_REQUEST;
    data: ApiTruckAddRequestT;
    companyId: CompanyIdT;
};

export type AddTruckRequestBeginActionT = {
    type: typeof ADD_TRUCK_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type AddTruckRequestErrorActionT = {
    type: typeof ADD_TRUCK_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type AddTruckRequestSuccessActionT = {
    type: typeof ADD_TRUCK_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type DeleteTrucksRequestActionT = {
    type: typeof DELETE_TRUCKS_REQUEST;
    truckIds: TruckIdT[];
    companyId: CompanyIdT;
};

export type DeleteTrucksRequestBeginActionT = {
    type: typeof DELETE_TRUCKS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type DeleteTrucksRequestErrorActionT = {
    type: typeof DELETE_TRUCKS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DeleteTrucksRequestSuccessActionT = {
    type: typeof DELETE_TRUCKS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type ActivationTrucksRequestActionT = {
    type: typeof ACTIVATION_TRUCKS_REQUEST;
    truckIds: TruckIdT[];
    ratesPerKm: Array<number | null>;
    companyId: CompanyIdT;
};

export type ArchiveTrucksRequestActionT = {
    type: typeof ARCHIVE_TRUCKS_REQUEST;
    truckIds: TruckIdT[];
    companyId: CompanyIdT;
    reason: string;
};

export type AssignToContractRequestActionT = {
    type: typeof ASSIGN_TO_CONTRACT_TRUCKS_REQUEST;
    truckIds: TruckIdT[];
    companyId: CompanyIdT;
    isContracted: boolean;
};

export type UpdateTruckActionT = {
    type: typeof UPDATE_TRUCK_REQUEST;
    companyId: CompanyIdT;
    truckId: TruckIdT;
    updateTruck: ApiTruckUpdateRequestT;
};

export type UpdateTrucksRequestBeginActionT = {
    type: typeof UPDATE_TRUCKS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type UpdateTrucksRequestErrorActionT = {
    type: typeof UPDATE_TRUCKS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type UpdateTrucksRequestSuccessActionT = {
    type: typeof UPDATE_TRUCKS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type FetchTruckDetailsActionT = {
    type: typeof FETCH_TRUCK_DETAILS_REQUEST;
    companyId: CompanyIdT;
    truckId: TruckIdT;
    options?: FetchOptionsT;
};

export type FetchTruckDetailsBeginActionT = {
    type: typeof FETCH_TRUCK_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchTruckDetailsErrorActionT = {
    type: typeof FETCH_TRUCK_DETAILS_REQUEST_ERROR;
    companyId: CompanyIdT;
    error: Error;
};

export type FetchTruckDetailsSuccessActionT = {
    type: typeof FETCH_TRUCK_DETAILS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
    truckId: TruckIdT;
    details: ApiTruckDetailsT | null;
};

export type FetchTrucksStatsActionT = {
    type: typeof FETCH_TRUCKS_STATS_REQUEST;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchTrucksStatsBeginActionT = {
    type: typeof FETCH_TRUCKS_STATS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchTrucksStatsErrorActionT = {
    type: typeof FETCH_TRUCKS_STATS_REQUEST_ERROR;
    companyId: CompanyIdT;
    error: Error;
};

export type FetchTrucksStatsSuccessActionT = {
    type: typeof FETCH_TRUCKS_STATS_REQUEST_SUCCESS;
    companyId: CompanyIdT;
    stats: CompanyTrucksStatsT | null;
};

export type TrucksActionT =
    | FetchTrucksPageActionT
    | FetchTrucksPageBeginActionT
    | FetchTrucksPageSuccessActionT
    | FetchTrucksPageErrorActionT
    | ResetTrucksActionT
    | AddTruckRequestActionT
    | AddTruckRequestBeginActionT
    | AddTruckRequestErrorActionT
    | AddTruckRequestSuccessActionT
    | DeleteTrucksRequestActionT
    | DeleteTrucksRequestBeginActionT
    | DeleteTrucksRequestErrorActionT
    | DeleteTrucksRequestSuccessActionT
    | ActivationTrucksRequestActionT
    | ArchiveTrucksRequestActionT
    | UpdateTruckActionT
    | UpdateTrucksRequestBeginActionT
    | UpdateTrucksRequestErrorActionT
    | UpdateTrucksRequestSuccessActionT
    | FetchTrucksStatsActionT
    | FetchTrucksStatsBeginActionT
    | FetchTrucksStatsErrorActionT
    | FetchTrucksStatsSuccessActionT
    | FetchTruckDetailsActionT
    | FetchTruckDetailsBeginActionT
    | FetchTruckDetailsErrorActionT
    | FetchTruckDetailsSuccessActionT;
